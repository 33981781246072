import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import PartnerCard from "../components/partner-card"

const Contact = ({ data }) => (
  <Layout>
    <SEO
      title="Celantur Partners - Who we work with"
      description="Read more about our trusted partners."
    />
    <section className="pt-8 pt-md-11">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-8 text-center mb-8">
            <h1 className="display-3 font-weight-bold">Our Partners</h1>
          </div>
        </div>
        <div className="row">
          <PartnerCard
            name="NVIDIA"
            color="#76b900"
            image={data.logoNvidia.childImageSharp.fluid}
            text="Market leader of visual computing"
            link="https://www.nvidia.com/"
          />

          <PartnerCard
            name="tech2b"
            color="#009fd7"
            image={data.logoTech2b.childImageSharp.fluid}
            text="Upper Austria’s technology-oriented incubator"
            link="https://www.tech2b.at/"
          />

          <PartnerCard
            name="The Blue Minds Company"
            color="#000000"
            image={data.logoBlueMinds.childImageSharp.fluid}
            text="Digitalization | Investments | Venturing"
            link="https://www.blueminds-company.com/"
          />

          <PartnerCard
            name="TECHHOUSE"
            color="#FFA900"
            image={data.logoTechhouse.childImageSharp.fluid}
            text="Innovation hub for enterprises and start-ups, focusing on AI and Cyber Security"
            link="https://www.tech-house.io/"
          />

          <PartnerCard
            name="Amazon Web Services"
            color="#f8991d"
            image={data.logoAws.childImageSharp.fluid}
            text="Cloud computing services"
            link="https://aws.amazon.com/"
          />

          <PartnerCard
            name="LIT Open Innovation Center"
            color="#0084BB"
            image={data.logoOic.childImageSharp.fluid}
            text="Research and development center for AI, Industry 4.0 and IT Security"
            link="https://www.jku.at/linz-institute-of-technology/das-lit/lit-open-innovation-center/"
          />

          <PartnerCard
            name="Summereder &amp; Pichler Rechtsanw&auml;lte"
            color="#7c6041"
            image={data.logoSummereder.childImageSharp.fluid}
            text="Law firm with a focus on data protection and IT"
            link="https://www.rechtsanwalt-leonding.at/"
          />

          <PartnerCard
            name="IÖB Innovationsplattform"
            color="#dd1f39"
            image={data.logoIoeb.childImageSharp.fluid}
            text="Austrian Innovation Procurement Platform"
            link="https://www.ioeb-innovationsplattform.at/marktplatz-innovation/detail/celantur-automatische-datenanonymisierung-fuer-bilder-und-videos/"
          />

          <PartnerCard
            name="C.W. Franz, Psychologe"
            color="#333"
            image={data.logoCWFranz.childImageSharp.fluid}
            text="Organizational psychologist"
            link="https://www.cwfranz.at/"
          />

          <PartnerCard
            name="Digitalregion Oberösterreich"
            color="#3ec0f1"
            image={data.logoDigitalRegionOoe.childImageSharp.fluid}
            text="Industrial CloudPool Upper Austria"
            link="https://www.digitalregion.at/cloudpool/"
          />

          <PartnerCard
            name="Land Oberösterreich"
            color="#EF3B42"
            image={data.logoLandOoe.childImageSharp.fluid}
            text="Dieses Projekt wird aus Mitteln des Landes Oberösterreich kofinanziert. Nähere Informationen zu IWB/EFRE finden Sie auf www.iwb2020.at"
            link="https://www.iwb2020.at/"
          />

          <PartnerCard
            name="Europäischer Fonds für regionale Entwicklung"
            color="#003399"
            image={data.logoEfre.childImageSharp.fluid}
            text="Dieses Projekt wird aus Mitteln des Europäischen Fonds für regionale Entwicklung kofinanziert. Nähere Informationen zu IWB/EFRE finden Sie auf www.iwb2020.at"
            link="https://www.efre.gv.at/"
          />
        </div>
      </div>
    </section>
  </Layout>
)

export default Contact

export const logo = graphql`
  fragment logoPartners on File {
    childImageSharp {
      fluid(maxHeight: 150) {
        ...GatsbyImageSharpFluid
        ...GatsbyImageSharpFluidLimitPresentationSize 
      }
    }
  }
`

export const query = graphql`
  query {
    logoAws: file(relativePath: { eq: "aws-logo.png" }) {
      ...logoPartners
    }
    logoSummereder: file(relativePath: { eq: "logo-summereder-pichler.png" }) {
      ...logoPartners
    }
    logoTechhouse: file(relativePath: { eq: "techhouse.png" }) {
      ...logoPartners
    }
    logoTech2b: file(relativePath: { eq: "tech2b.png" }) {
      ...logoPartners
    }
    logoNvidia: file(relativePath: { eq: "nvidia-inception.png" }) {
      ...logoPartners
    }
    logoOic: file(relativePath: { eq: "lit-oic-logo.png" }) {
      ...logoPartners
    }
    logoIoeb: file(relativePath: { eq: "ioeb-ausgezeichnet.png" }) {
      ...logoPartners
    }
    logoBlueMinds: file(relativePath: { eq: "blue-minds-logo.png" }) {
      ...logoPartners
    }
    logoCWFranz: file(relativePath: { eq: "cwfranz-logo.png" }) {
      ...logoPartners
    }
    logoDigitalRegionOoe: file(relativePath: { eq: "digitalregion-oberoesterreich-logo.png" }) {
      ...logoPartners
    }
    logoLandOoe: file(relativePath: { eq: "landooe-logo.jpg" }) {
      ...logoPartners
    }
    logoEfre: file(relativePath: { eq: "efre-logo.jpg" }) {
      ...logoPartners
    }    
  }
`
