import React from "react"
import Img from "gatsby-image"
import PropTypes from "prop-types"

const PartnerCard = ({ name, color, image, text = "", link, city = "", country = "" }) => {
  return (
    <>
      <div className="col-12 col-md-6 col-lg-4 text-center aos-init aos-animate mb-8 d-flex">
        <div
          className="card card-border shadow-lift lift lift-lg"
          style={{ borderTopColor: color }}
        >
          <div className="card-body text-center h-100">
            <div className="img-fluid mb-5">
              <Img fluid={image} alt={name} className="mx-auto"/>
            </div>
            <h4 className="text-gray-900 font-weight-bold mb-1">
              {name}
            </h4>
            {(country.length > 0 && city.length > 0) &&
              <p className="text-gray-500 mb-5">{city}, {country}</p>
            }
            <p className="text-gray-500 mb-5" style={{ fontSize: "0.85em" }}>{text}</p>
            <a
              href={link}
              className="stretched-link"
              target="_blank"
              rel="noopener noreferrer"
              aria-label={name}
            >{" "}</a>
          </div>
        </div>
      </div>
    </>
  )
}

PartnerCard.propTypes = {
  name: PropTypes.node.isRequired,
  color: PropTypes.node,
  image: PropTypes.node,
  text: PropTypes.node,
  link: PropTypes.node,
}

export default PartnerCard
